<template>
  <div class="container-fluid">
    <div class="row py-3">
      <div class="col-12">
        <div class="row justify-content-center">
          <div class="col-10 col-sm-8 col-md-4">
            <div class="form-group">
              <select name="tournament" id="tournament" class="form-control" @change="changeTournament" v-model="tournamentId">
                <option v-for="(tournament, ind) in tournaments" :key="ind" :value="tournament.id">{{ tournament.name }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="table-responsive">
          <table class="table table-hover table-striped table-bordered">
            <thead>
              <th>{{ $t('position') }}</th>
              <th>{{ $t('player') }}</th>
              <th v-if="isPremium">{{ $t('price') }}</th>
              <th v-if="isPremium">
                <img src="../../../assets/images/contract.png" alt="Contratos" title="Contratos" class="img-icon">
              </th>
              <th>
                <img src="../../../assets/images/played.png" alt="Partidos jugados" title="Partidos jugados" class="img-icon">
              </th>
              <th>
                <img src="../../../assets/images/assist.png" alt="Asistencias" title="Asistencias" class="img-icon">
              </th>
              <th>
                <img src="../../../assets/images/goals.png" alt="Goles" title="Goles" class="img-icon">
              </th>
              <th>
                <img src="../../../assets/images/best.png" alt="Mvps" title="Mvps" class="img-icon">
              </th>
              <th>
                <img src="../../../assets/images/yellow.png" alt="Amarillas" title="Amarillas" class="img-icon">
              </th>
              <th>
                <img src="../../../assets/images/red.png" alt="Rojas" title="Rojas" class="img-icon">
              </th>
            </thead>
            <tbody>
              <tr>
                <td :colspan="colspan" class="table-primary text-center">
                  <strong>{{ $tc('goalkeeper', 2) }}</strong>
                  </td>
              </tr>
              <tr v-for="player in gks" :key="player.id">
                <td>{{ player.position | completePosition }}</td>
                <td>
                  <router-link :to="{ name: 'PlayerHome', params: { lang: lang, console: console, playerSlug: player.slug } }">
                    <img :src="player.avatar" :alt="player.nick" class="img-icon mr-2">
                    {{ player.nick }}
                  </router-link>
                </td>
                <td v-if="isPremium" class="text-right">{{ player.price }}</td>
                <td v-if="isPremium" class="text-right">{{ player.rounds }}</td>
                <td class="text-right">{{ player.pjs }}</td>
                <td class="text-right">{{ player.assists }}</td>
                <td class="text-right">{{ player.goals }}</td>
                <td class="text-right">{{ player.bests }}</td>
                <td class="text-right">{{ player.yellows }}</td>
                <td class="text-right">{{ player.reds }}</td>
              </tr>
              <tr>
                <td :colspan="colspan" class="table-primary text-center">
                  <strong>{{ $t('defense') | toCapitalize }}</strong>
                  </td>
              </tr>
              <tr v-for="player in defenses" :key="player.id">
                <td>{{ player.position | completePosition }}</td>
                <td>
                  <router-link :to="{ name: 'PlayerHome', params: { lang: lang, console: console, playerSlug: player.slug } }">
                    <img :src="player.avatar" :alt="player.nick" class="img-icon mr-2">
                    {{ player.nick }}
                  </router-link>
                </td>
                <td v-if="isPremium" class="text-right">{{ player.price }}</td>
                <td v-if="isPremium" class="text-right">{{ player.rounds }}</td>
                <td class="text-right">{{ player.pjs }}</td>
                <td class="text-right">{{ player.assists }}</td>
                <td class="text-right">{{ player.goals }}</td>
                <td class="text-right">{{ player.bests }}</td>
                <td class="text-right">{{ player.yellows }}</td>
                <td class="text-right">{{ player.reds }}</td>
              </tr>
              <tr>
                <td :colspan="colspan" class="table-primary text-center">
                  <strong>{{ $t('midfielders') }}</strong>
                  </td>
              </tr>
              <tr v-for="player in midfielders" :key="player.id">
                <td>{{ player.position | completePosition }}</td>
                <td>
                  <router-link :to="{ name: 'PlayerHome', params: { lang: lang, console: console, playerSlug: player.slug } }">
                    <img :src="player.avatar" :alt="player.nick" class="img-icon mr-2">
                    {{ player.nick }}
                  </router-link>
                </td>
                <td v-if="isPremium" class="text-right">{{ player.price }}</td>
                <td v-if="isPremium" class="text-right">{{ player.rounds }}</td>
                <td class="text-right">{{ player.pjs }}</td>
                <td class="text-right">{{ player.assists }}</td>
                <td class="text-right">{{ player.goals }}</td>
                <td class="text-right">{{ player.bests }}</td>
                <td class="text-right">{{ player.yellows }}</td>
                <td class="text-right">{{ player.reds }}</td>
              </tr>
              <tr>
                <td :colspan="colspan" class="table-primary text-center">
                  <strong>{{ $t('front') }}</strong>
                  </td>
              </tr>
              <tr v-for="player in front" :key="player.id">
                <td>{{ player.position | completePosition }}</td>
                <td>
                  <router-link :to="{ name: 'PlayerHome', params: { lang: lang, console: console, playerSlug: player.slug } }">
                    <img :src="player.avatar" :alt="player.nick" class="img-icon mr-2">
                    {{ player.nick }}
                  </router-link>
                </td>
                <td v-if="isPremium" class="text-right">{{ player.price }}</td>
                <td v-if="isPremium" class="text-right">{{ player.rounds }}</td>
                <td class="text-right">{{ player.pjs }}</td>
                <td class="text-right">{{ player.assists }}</td>
                <td class="text-right">{{ player.goals }}</td>
                <td class="text-right">{{ player.bests }}</td>
                <td class="text-right">{{ player.yellows }}</td>
                <td class="text-right">{{ player.reds }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      tournaments: [],
      players: [],
      tournamentId: '',
      front: [],
      gks: [],
      defenses: [],
      midfielders: [],
      colspan: 9,
      isPremium: false
    }
  },
  computed: {
    ...mapGetters([
      'lang',
      'console',
      'teamFront'
    ])
  },
  mounted() {
    this.$store.dispatch('SET_MENU_PRIMARY_TEAM', 'home')
  },
  created() {
    this.fetchData()
  },
  methods: {
    changeTournament() {
      this.$router.push({ name: 'TeamHome', params: { lang: this.lang, console: this.console, teamSlug: this.teamFront.slug }, query: { tournament: this.tournamentId } })
      this.fetchData()
    },
    fetchData() {
      const lang = this.$route.params.lang
      const console = this.$route.params.console
      const slug = this.$route.params.teamSlug
      const params = {
        tournament: this.$route.query.tournament
      }
      this.path = `${lang}/console/${console}/team/${slug}/players`
      this.$axios.get(this.path, { params }).then((response) => {
        const data = response.data
        this.tournaments = data.tournaments_where_i_play
        this.tournamentId = data.current_tournament_id
        this.isPremium = data.is_premium
        this.colspan = (data.is_premium === true ? 10 : 9)
        this.front = data.players.filter(player => {
          return player.position === 'ST'
        })
        this.gks = data.players.filter(player => {
          return player.position === 'GK'
        })
        this.defenses = data.players.filter(player => {
          return player.position === 'RB' || player.position === 'LB' || player.position === 'CB' || player.position === 'CDM'
        })
        this.midfielders = data.players.filter(player => {
          return player.position === 'RM' || player.position === 'LM' || player.position === 'CAM'
        })
      })
    }
  }
}
</script>
